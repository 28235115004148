import React, { Component } from 'react';

interface HeaderProps {
    path: string;
    logout(): void;
    search(): void;
}

export class Header extends Component<HeaderProps, {}> {
    render() {
        const isLoginPage = this.props.path === 'login';
        const items = isLoginPage
            ? []
            : [
                  {
                      text: 'Search Individuals',
                      click: this.props.search,
                      disabled: this.props.path === 'individuals',
                  },
                  {
                      text: 'Logout',
                      click: this.props.logout,
                      disabled: this.props.path === '',
                  },
              ];

        const navBarItems = items.map((item) => (
            <li key={'navbar-' + item.text} className="nav-item">
                <button
                    disabled={item.disabled}
                    className="nav-button"
                    onClick={item.click}
                >
                    {item.text}
                </button>
            </li>
        ));

        return (
            <ul className="navbar">
                <li className="navbar-brand">Family Tree</li>
                {navBarItems}
            </ul>
        );
    }
}
