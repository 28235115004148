import { Individual, LifeEvent } from './Data';

export function nameOf(individual: Individual) {
    return individual
        ? individual.lastName + ', ' + individual.firstNames
        : 'Unknown';
}

export function lifetimeOf(individual: Individual) {
    return lifetime(individual);
}

export function getYear(date: string) {
    if (!date) {
        return null;
    }
    // All runs of digits, filtered to extact those of 4-digit length.
    const matches = Array.from(date.matchAll(/(\d+)/g), (x) => x[0]).filter(
        (x) => x.length === 4,
    );
    for (const match of matches) {
        return parseInt(match);
    }
    return null;
}

function nullDate(event?: LifeEvent) {
    return !event || !event.date;
}

function yearOf(event?: LifeEvent) {
    if (!event || !event.date) {
        return '?';
    }
    const year = getYear(event.date);
    return year || '?';
}

//TODO: USe 1 consistently!
export function lifetime(individual: Individual) {
    if (
        !individual ||
        (nullDate(individual.birth) && nullDate(individual.death))
    ) {
        return '';
    }
    const birth = yearOf(individual.birth);
    const death = yearOf(individual.death);
    return '(' + birth + ' - ' + death + ')';
}

export function nameAndLifetimeOf(individual: Individual) {
    const l = lifetime(individual);
    return nameOf(individual) + (l ? ' - ' + l : '');
}
