import React, { Component } from 'react';
import { nameAndLifetimeOf } from './Utils';
import {
    Family,
    Individual,
    IndividualCallback,
    LifeEvent,
    Sex,
} from './Data';

function formatEvent(event?: LifeEvent) {
    if (event === undefined || (!event.date && !event.location)) {
        return '';
    }
    let s = '';
    if (event.date) {
        s += event.date;
    }
    if (event.location) {
        if (s) {
            s += ', ';
        }
        s += event.location;
    }
    return s;
}

function formatChildren(family: Family, callback: IndividualCallback) {
    if (family.children.length === 0) {
        return null;
    }
    let first = true;
    const f = (child: Individual) => {
        const leftColumn = first ? (
            <span className="field-title">Children:</span>
        ) : null;
        first = false;
        return (
            <tr key={family.id + '-' + child.id}>
                <td>{leftColumn}</td>
                <td>
                    <button onClick={() => callback(child.id)}>
                        {nameAndLifetimeOf(child)}
                    </button>
                </td>
            </tr>
        );
    };
    return family.children.map(f);
}

function spouseOf(
    individual: Individual,
    family: Family,
): Individual | undefined {
    for (const partner of family.partners) {
        if (partner.id !== individual.id) {
            return partner;
        }
    }
    return undefined;
}

function formatFamilies(
    individual: Individual,
    families: Family[],
    callback: IndividualCallback,
) {
    const f = (family: Family) => {
        const spouse = spouseOf(individual, family);
        const spouseBox =
            spouse !== undefined ? (
                <button onClick={() => callback(spouse.id)}>
                    {nameAndLifetimeOf(spouse)}
                </button>
            ) : null;
        return (
            <table key={'family' + family.id} className="family">
                <tbody>
                    <tr>
                        <td>
                            <span className="field-title">Partner:</span>
                        </td>
                        <td>{spouseBox}</td>
                    </tr>
                    <tr>
                        <td>
                            <span className="field-title">Married:</span>
                        </td>
                        <td>{formatEvent(family.married)}</td>
                    </tr>
                    {formatChildren(family, callback)}
                </tbody>
            </table>
        );
    };
    return families.map(f);
}

function formatParents(parents: Individual[], callback: IndividualCallback) {
    let first = true;
    const f = (p: Individual) => {
        const leftColumn = first ? (
            <span className="field-title">Parents:</span>
        ) : null;
        first = false;
        return (
            <tr key={'parent-' + p.id}>
                <td>{leftColumn}</td>
                <td>
                    <button onClick={() => callback(p.id)}>
                        {nameAndLifetimeOf(p)}
                    </button>
                </td>
            </tr>
        );
    };
    return parents.map(f);
}

interface IndividualDetailProps {
    individual: Individual;
    showDetails: IndividualCallback;
    showDescendants: IndividualCallback;
    showAncestors: IndividualCallback;
}

interface IndividualDetailState {}

export class IndividualDetail extends Component<
    IndividualDetailProps,
    IndividualDetailState
> {
    render() {
        const individual = this.props.individual;
        // console.log(JSON.stringify(individual));
        const families = individual.partnerIn || [];
        const parents = individual.childOf ? individual.childOf.partners : [];

        const birth = formatEvent(individual.birth);
        const death = formatEvent(individual.death);
        const buried = formatEvent(individual.buried);
        const baptism = formatEvent(individual.baptism);

        const sex =
            individual.sex === Sex.Male
                ? 'Male'
                : individual.sex === Sex.Female
                ? 'Female'
                : '?';
        const notes = (
            <div id="notes">
                <span className="field-title">Notes:</span>
                {individual.note ? individual.note : null}
            </div>
        );

        const fields = [
            ['Last name', individual.lastName],
            ['First names', individual.firstNames],
            ['Sex', sex],
            ['Birth', birth],
            ['Death', death],
            ['Buried', buried],
            ['Baptism', baptism],
            ['Occupation', individual.occupation],
        ];

        const fieldsRows = fields.map((x) => {
            return (
                <tr key={x[0]}>
                    <td>
                        <span className="field-title">{x[0]}:</span>
                    </td>
                    <td>{x[1]}</td>
                </tr>
            );
        });

        const parentsRows = formatParents(parents, this.props.showDetails);

        const familiesRows = formatFamilies(
            this.props.individual,
            families,
            this.props.showDetails,
        );

        return (
            <div className="individual-detail">
                <div id="name">
                    {individual.firstNames + ' ' + individual.lastName}
                </div>
                <table className="individual-detail-table">
                    <tbody>
                        {fieldsRows}
                        {parentsRows}
                    </tbody>
                </table>

                <span className="field-title">Families:</span>
                {familiesRows}
                {notes}
                <div>
                    <button
                        onClick={() =>
                            this.props.showDescendants(individual.id)
                        }
                    >
                        View Descendants Tree
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => this.props.showAncestors(individual.id)}
                    >
                        View Ancestors Tree
                    </button>
                </div>
            </div>
        );
    }
}
