export enum Sex {
    Male = 'M',
    Female = 'F',
    Unknown = '?',
}

export class LifeEvent {
    date?: string;
    location?: string;

    constructor(date?: string, location?: string) {
        this.date = date;
        this.location = location;
    }
}

export class Family {
    id: number;
    married?: LifeEvent;
    partners: Individual[];
    children: Individual[];
    note?: string;

    constructor(id: number, married?: LifeEvent, note?: string) {
        this.id = id;
        this.married = married;
        this.partners = [];
        this.children = [];
        this.note = note;
    }
}

export type IndividualCallback = (individualId: number) => void;

export class Individual {
    id: number;
    firstNames?: string;
    lastName?: string;
    sex?: Sex;
    birth?: LifeEvent;
    death?: LifeEvent;
    buried?: LifeEvent;
    baptism?: LifeEvent;
    occupation?: string;
    childOf?: Family;
    partnerIn: Family[];
    note?: string;

    constructor(
        id: number,
        firstNames?: string,
        lastName?: string,
        sex?: Sex,
        birth?: LifeEvent,
        death?: LifeEvent,
        buried?: LifeEvent,
        baptism?: LifeEvent,
        occupation?: string,
        childOf?: Family,
        note?: string,
    ) {
        this.id = id;
        this.firstNames = firstNames;
        this.lastName = lastName;
        this.sex = sex;
        this.birth = birth;
        this.death = death;
        this.buried = buried;
        this.baptism = baptism;
        this.occupation = occupation;
        this.childOf = childOf;
        this.partnerIn = [];
        this.note = note;
    }

    parents(): Individual[] {
        return this.childOf ? this.childOf.partners : [];
    }

    fullName(): string {
        let names = [];
        if (this.lastName) {
            names.push(this.lastName);
        }
        if (this.firstNames) {
            names.push(this.firstNames);
        }
        return names.join(', ');
    }

    compare(other: Individual): number {
        return this.fullName().localeCompare(other.fullName());
    }
}

export class Database {
    private families: Map<number, Family> = new Map<number, Family>();
    private individuals: Map<number, Individual> = new Map<
        number,
        Individual
    >();
    private url: string;

    constructor() {
        if (!process.env.REACT_APP_DATA_URL) {
            throw new Error(
                'Must specify REACT_APP_DATA_URL environment variable',
            );
        }
        this.url = process.env.REACT_APP_DATA_URL;
    }

    async connect(username: string, password: string): Promise<void> {
        if (!username || !password) {
            throw new Error('Login required');
        }
        const init: RequestInit = {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password,
            }),
            mode: 'cors',
            cache: 'default',
        };
        let response = await fetch(this.url, init);
        if (!response.ok) {
            throw new Error(`Error ${response.status}:${response.statusText}`);
        }
        this.parseData(await response.json());
    }

    private parseData(data: any) {
        this.families.clear();
        this.individuals.clear();
        const parseId = (key: string): number => {
            const id = parseInt(key);
            if (isNaN(id)) {
                throw new Error(`Failed to parse id ${id}`);
            }
            return id;
        };

        for (const key in data.families) {
            const id = parseId(key);
            if (this.families.has(id)) {
                throw new Error(`Duplicate family id ${id}`);
            }
            const f = data.families[key];
            const married = new LifeEvent(f.married.date, f.married.location);
            let family = new Family(id, married, f.note);
            this.families.set(id, family);
        }

        for (const key in data.individuals) {
            const id = parseId(key);
            if (this.individuals.has(id)) {
                throw new Error(`Duplicate individual id ${id}`);
            }
            const sexOf = (ch: string) => {
                if (ch === 'F') {
                    return Sex.Female;
                }
                if (ch === 'M') {
                    return Sex.Male;
                }
                return Sex.Unknown;
            };
            const maybeEvent = (date?: string, location?: string) => {
                if (!date && !location) {
                    return undefined;
                }
                return new LifeEvent(date, location);
            };
            const i = data.individuals[key];
            const childIn = i.child_in_family
                ? this.families.get(parseId(i.child_in_family))
                : undefined;
            let individual = new Individual(
                id,
                i.first_names,
                i.last_name,
                sexOf(i.sex),
                maybeEvent(i.birth.date, i.birth.location),
                maybeEvent(i.death.date, i.death.location),
                maybeEvent(i.buried.date, i.buried.location),
                maybeEvent(i.baptism.date, i.baptism.location),
                i.occupation,
                childIn,
                i.note,
            );
            this.individuals.set(id, individual);
            if (childIn) {
                childIn.children.push(individual);
            }

            for (const familyKey of i.spouse_in_family) {
                const family = this.families.get(parseId(familyKey));
                if (family === undefined) {
                    throw new Error(
                        `Individual ${id} is spouse in invalid family ${familyKey}`,
                    );
                }
                individual.partnerIn.push(family);
                family.partners.push(individual);
            }
        }
        console.log(
            `Loaded ${this.individuals.size} individuals and ${this.families.size} families`,
        );
    }

    searchIndividuals(query: string): Individual[] {
        if (!query) {
            return [];
        }
        // Strip any ',' characters, and convert the query into a regex
        // with each word with .* in between. So that "chris pearce" matches
        // "christopher pearce".
        const regex = new RegExp(
            query.replace(',', '').split(' ').join('.*'),
            'i',
        );
        let results = [];
        for (const individual of this.individuals.values()) {
            const formalName =
                (individual.firstNames || '') + (individual.lastName || '');
            const informalName =
                (individual.lastName || '') + (individual.firstNames || '');
            if (formalName.match(regex) || informalName.match(regex)) {
                results.push(individual);
            }
        }
        results.sort((a, b) => a.compare(b));
        return results;
    }

    searchFamilies(query: string) {
        return [];
    }

    individual(id: number): Individual | null {
        return this.individuals.get(id) || null;
    }

    family(id: number): Family | null {
        return this.families.get(id) || null;
    }
}
