import { LoginBox } from './LoginBox';
import React, { Component } from 'react';
import { Search } from './Search';
import { IndividualDetail } from './IndividualDetail';
import { Header } from './Header';
import { Descendants, Ancestors } from './Descendants';
import { Database } from './Data';
import './App.css';

type AppProps = {};
type AppState = {
    path: string;
    error: string;
    loginInProgress: boolean;
};

class App extends Component<AppProps, AppState> {
    database: Database = new Database();
    state: AppState = {
        path: window.location.hash.replace('#', ''),
        error: '',
        loginInProgress: false,
    };

    constructor(props: AppProps) {
        super(props);

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.search = this.search.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.error = this.error.bind(this);
        this.showAncestors = this.showAncestors.bind(this);
        this.showDescendants = this.showDescendants.bind(this);

        window.addEventListener('popstate', (e) => {
            this.setState({
                path: window.location.hash.replace('#', ''),
            });
        });

        // Ensure we start at the login screen.
        if (this.state.path === '') {
            // Note: We can't call our navigate() function here, as it calls
            // setState(), which won't work in a constructor as we're not
            // mounted yet.
            this.state.path = 'login';
            window.history.pushState(
                {},
                'Family Tree: Login',
                '#' + this.state.path,
            );
        }

        this.connect();
    }

    async connect() {
        console.log('App.connect()');
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        if (!username || !password) {
            this.navigate('Family Tree', 'login');
            return;
        }
        this.login(username, password);
    }

    error(message: string) {
        const path = 'error';
        window.history.pushState({}, 'Error', path);
        this.setState({
            path: path,
            error: message,
        });
    }

    async login(username: string, password: string) {
        console.log('login u:' + username + ' p:' + password);
        this.setState({
            loginInProgress: true,
        });

        try {
            await this.database.connect(username, password);
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            this.navigate('Individuals', 'individuals');
        } catch (e) {
            this.error('Login Failed: ' + e.message);
            localStorage.clear();
        } finally {
            this.setState({
                loginInProgress: false,
            });
        }
    }

    logout() {
        console.log('logout');
        localStorage.clear();
        this.navigate('Family Tree: Login', 'login');
    }

    search() {
        this.navigate('Individuals', 'individuals');
    }

    showDetails(individualId: number) {
        this.navigate('Individual detail', 'individuals/' + individualId);
    }

    showAncestors(individualId: number) {
        this.navigate(
            'Individual ancestors',
            'individuals/' + individualId + '/ancestors',
        );
    }

    showDescendants(individualId: number) {
        this.navigate(
            'Individual descendants',
            'individuals/' + individualId + '/descendants',
        );
    }

    navigate(title: string, path: string) {
        // TODO: Use an enum for the path/title for consistency.
        console.log('Navigate ' + path);
        window.history.pushState({}, title, '#' + path);
        this.setState({
            path: window.location.hash.replace('#', ''),
        });
    }

    render() {
        const chunks = this.state.path.split('/').filter(nonNull);

        const header = (
            <Header
                path={this.state.path}
                logout={this.logout}
                search={this.search}
            />
        );
        console.log('App.render ' + this.state.path);

        // URL: /error
        if (this.state.path === 'error') {
            return <div>Error; {this.state.error}</div>;
        }

        // URL: /login
        if (this.state.path === 'login') {
            if (this.state.loginInProgress) {
                return <div>Logging in...</div>;
            }
            return (
                <div>
                    {header}
                    <LoginBox login={this.login} />
                </div>
            );
        }

        // URL: individuals
        if (this.state.path === 'individuals') {
            return (
                <div>
                    {header}
                    <Search
                        database={this.database}
                        showDetails={this.showDetails}
                    />
                </div>
            );
        }

        if (chunks.length >= 2 && chunks[0] === 'individuals') {
            const id = parseInt(chunks[1]);
            if (isNaN(id)) {
                return <div>Invalid individual id</div>;
            }
            if (chunks.length === 2) {
                // URL: individuals/$id
                const individual = this.database.individual(id);
                if (!individual) {
                    return (
                        <div>
                            {header}
                            <div>Invalid individual ID {id}</div>
                        </div>
                    );
                }
                return (
                    <div>
                        {header}
                        <IndividualDetail
                            individual={individual}
                            showDetails={this.showDetails}
                            showDescendants={this.showDescendants}
                            showAncestors={this.showAncestors}
                        />
                    </div>
                );
            }

            if (chunks.length === 3) {
                const individual = this.database.individual(id);
                if (!individual) {
                    return (
                        <div>
                            {header}
                            <div>Invalid individual ID {id}</div>
                        </div>
                    );
                }
                if (chunks[2] === 'descendants') {
                    // URL: individuals/$id/descendants
                    return (
                        <div>
                            {header}
                            <Descendants
                                individual={individual}
                                showDetails={this.showDetails}
                            />
                        </div>
                    );
                }
                if (chunks[2] === 'ancestors') {
                    // URL: individuals/$id/ancestors
                    return (
                        <div>
                            {header}
                            <Ancestors
                                individual={individual}
                                showDetails={this.showDetails}
                            />
                        </div>
                    );
                }
            }
        }

        // Not a valid route, error!
        return <div>Invalid path</div>;
    }
}

function nonNull(x: string) {
    return x;
}

export default App;
