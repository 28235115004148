import React, { Component } from 'react';

interface LoginBoxProps {
    login(username: string, password: string): void;
}

interface LoginBoxState {
    username: string;
    password: string;
}

export class LoginBox extends Component<LoginBoxProps, LoginBoxState> {
    constructor(props: LoginBoxProps) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    render() {
        return (
            <div>
                <form
                    className="LoginBox"
                    onSubmit={(event) => {
                        event.preventDefault();
                        this.props.login(
                            this.state.username,
                            this.state.password,
                        );
                    }}
                >
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="field-title">
                                        Username:
                                    </span>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="username"
                                        value={this.state.username}
                                        onChange={(event) => {
                                            this.setState({
                                                username: event.target.value,
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="field-title">
                                        Password:
                                    </span>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={(event) => {
                                            this.setState({
                                                password: event.target.value,
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input type="submit" value="Login" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        );
    }
}
