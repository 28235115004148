import React, { Component } from 'react';
import { lifetimeOf, nameAndLifetimeOf } from './Utils';
import { Individual, IndividualCallback, Family } from './Data';

const BOX_PADDING = 5;
const BOX_HEIGHT = 80;
const BOX_MARGIN = 5;
const BOX_WIDTH = 175;
const ARROW_HEIGHT = BOX_HEIGHT;
const BLACK = 'black';
const RED = 'red';
const THICK = 2;
const THIN = 1;
const MAX_ZOOM = 10.0;
const MIN_ZOOM = 0.0;

interface Rect {
    key: string;
    x: number;
    y: number;
    width: number;
    height: number;
    stroke: string; // TODO: make enum
    fill: string; // TODO: make enum
    strokeWidth: number;
    id: number; // TODO: Not sure about this; should it be string? Or individual?
}

interface Text {
    key: string;
    x: number;
    y: number;
    width: number;
    height: number;
    text: string;
    id: number; // TODO: What about this?
}

interface Line {
    key: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    stroke: string; // TODO: make enum
    strokeWidth: number;
}

interface Shapes {
    rects: Rect[];
    texts: Text[];
    lines: Line[];
}

function addRect(
    shapes: Shapes,
    x: number,
    y: number,
    width: number,
    height: number,
    stroke: string,
    stroke_width: number,
    id: number,
) {
    shapes.rects.push({
        key: 'indi-rect-' + id,
        x: x,
        y: y,
        width: width,
        height: height,
        stroke: stroke || 'black',
        fill: 'transparent',
        strokeWidth: stroke_width || 1,
        id: id,
    });
}

function addText(
    shapes: Shapes,
    x: number,
    y: number,
    w: number,
    h: number,
    text: string,
    key: string,
    id: number,
) {
    // TODO: Maybe we don't need key to be passed in?
    shapes.texts.push({
        key: 'text-' + key,
        x: x,
        y: y,
        width: w,
        height: h,
        text: text,
        id: id,
    });
}

function addIndividualBox(
    x: number,
    y: number,
    individual: Individual,
    shapes: Shapes,
    stroke: string,
    stroke_width: number,
) {
    addRect(
        shapes,
        x,
        y,
        BOX_WIDTH,
        BOX_HEIGHT,
        stroke,
        stroke_width,
        individual.id,
    );
    const line_height = (BOX_HEIGHT - 4 * BOX_PADDING) / 3;

    addText(
        shapes,
        x + BOX_PADDING,
        y + BOX_PADDING + line_height,
        BOX_WIDTH - 2 * BOX_PADDING,
        line_height,
        individual.lastName || '?',
        'indi-lastname-' + individual.id,
        individual.id,
    );

    addText(
        shapes,
        x + BOX_PADDING,
        y + 2 * BOX_PADDING + 2 * line_height,
        BOX_WIDTH - 2 * BOX_PADDING,
        line_height,
        individual.firstNames || '?',
        'indi-first_names-' + individual.id,
        individual.id,
    );

    addText(
        shapes,
        x + BOX_PADDING,
        y + 3 * BOX_PADDING + 3 * line_height,
        BOX_WIDTH - 2 * BOX_PADDING,
        line_height,
        lifetimeOf(individual),
        'text-indi-lifetime-' + individual.id,
        individual.id,
    );
}

function addSpouseBox(
    x: number,
    y: number,
    individual: Individual,
    shapes: Shapes,
) {
    addIndividualBox(x, y, individual, shapes, BLACK, THIN);
}

function addDescendantBox(
    x: number,
    y: number,
    individual: Individual,
    shapes: Shapes,
) {
    addIndividualBox(x, y, individual, shapes, RED, THICK);
}

function addLine(
    shapes: Shapes,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string, // TODO: enum!
    stroke_width: number,
    key: string,
) {
    shapes.lines.push({
        key: key,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
        stroke: color,
        strokeWidth: stroke_width,
    });
}

interface Geometry {
    width: number;
    height: number;
    baseWidth: number;
    rowOffset: number;
    linkOffset: number;
}

type Geometries = Map<number, Geometry>;

interface AncestryTree {
    width: number;
    height: number;
    shapes: Shapes;
}

interface Loader {
    calculateGeometry(
        individual: Individual,
        geometries: Map<number, Geometry>,
    ): { width: number; height: number };

    walkIndividual(
        x: number,
        y: number,
        shapes: Shapes,
        individual: Individual,
        geometries: Map<number, Geometry>,
    ): void;
}

function spouseOf(individual: Individual, family: Family) {
    for (const partner of family.partners) {
        if (partner.id !== individual.id) {
            return partner;
        }
    }
    return undefined;
}

function familiesWithChildren(families: Family[]) {
    return families.filter((f) => f.children.length > 0);
}

class DescendantsLoader implements Loader {
    calculateGeometry(
        individual: Individual,
        geometries: Map<number, Geometry>,
    ) {
        // Only show spouses which produced children, as it's easier. :|
        const families = familiesWithChildren(individual.partnerIn);
        const numSpouses = families.length;
        const baseWidth =
            BOX_WIDTH + numSpouses * BOX_WIDTH + numSpouses * BOX_MARGIN * 2;
        let childrenWidth = 0;
        let childrenHeight = 0;
        let numChildren = 0;
        for (let family of families) {
            numChildren += family.children.length;
            for (let child of family.children) {
                const { width, height } = this.calculateGeometry(
                    child,
                    geometries,
                );
                childrenWidth += width;
                childrenHeight = Math.max(childrenHeight, height);
            }
        }
        // Account for margin between child rects.
        childrenWidth += BOX_MARGIN * Math.max(0, numChildren - 1);
        const width = Math.max(baseWidth, childrenWidth);
        const rowOffset = (width - baseWidth) / 2;

        const height =
            BOX_HEIGHT +
            (childrenHeight > 0 ? ARROW_HEIGHT + childrenHeight : 0);
        // Offset from the start of the box to the start of the
        // first individual's box (spouse or descendant).

        let f = individual.partnerIn.length === 0 ? 0.5 : 1.5;
        const linkOffset = rowOffset + f * BOX_WIDTH;

        const geometry = {
            width: width,
            height: height,
            baseWidth: baseWidth,
            rowOffset: rowOffset,
            linkOffset: linkOffset,
        };
        geometries.set(individual.id, geometry);

        return {
            width: width,
            height: height,
        };
    }

    walkIndividual(
        x: number,
        y: number,
        shapes: Shapes,
        individual: Individual,
        geometries: Map<number, Geometry>,
    ) {
        const geometry = geometries.get(individual.id);
        if (!geometry) {
            throw new Error(`Can't find geometry for ${individual.id}`);
        }
        let x_offset = geometry.rowOffset;

        let spouse_line_drop_offsets = [];
        let row_bottom = y + BOX_HEIGHT;

        let lineKey = 'indi-line-' + individual.id + '-';
        let counter = 1;

        const families = familiesWithChildren(individual.partnerIn);
        const num_families = families.length;
        if (num_families > 0) {
            let spouse = spouseOf(individual, families[0]);
            if (!spouse) {
                throw new Error(`Mising spouse for family ${families[0].id}`);
            }
            addSpouseBox(x + x_offset, y, spouse, shapes);

            // Draw lines down from first spouse to connect to descendant.
            // +------+  +----------+  +------+
            // |Spouse|  |Descendant|  |Spouse|
            // +--+---+  +------+---+  +-+----+  <-- row bottom
            //    |             |        |
            //    +---+---------+--------+       <-- link bottom
            //        |
            //        +                          <-- drop bottom
            let spouse_mid_x = x + x_offset + BOX_WIDTH / 2;
            let descendant_mid_x =
                x + x_offset + BOX_WIDTH + BOX_MARGIN + BOX_WIDTH / 2;
            let drop_x = x + x_offset + BOX_WIDTH;
            let link_bottom =
                row_bottom + BOX_HEIGHT * (num_families === 1 ? 0.333 : 0.25);
            let drop_bottom =
                row_bottom + BOX_HEIGHT * (num_families === 1 ? 0.66 : 0.5);

            addLine(
                shapes,
                spouse_mid_x,
                row_bottom,
                spouse_mid_x,
                link_bottom,
                BLACK,
                THIN,
                lineKey + counter++,
            );
            addLine(
                shapes,
                spouse_mid_x,
                link_bottom,
                drop_x,
                link_bottom,
                BLACK,
                THIN,
                lineKey + counter++,
            );
            addLine(
                shapes,
                drop_x,
                link_bottom,
                descendant_mid_x,
                link_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );
            addLine(
                shapes,
                descendant_mid_x,
                link_bottom,
                descendant_mid_x,
                row_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );
            addLine(
                shapes,
                drop_x,
                link_bottom,
                drop_x,
                drop_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );

            x_offset += BOX_WIDTH + BOX_MARGIN;
            spouse_line_drop_offsets.push([drop_x, drop_bottom]);
        }

        addDescendantBox(x + x_offset, y, individual, shapes);

        x_offset += BOX_WIDTH + BOX_MARGIN;

        if (families.length > 1) {
            let spouse = spouseOf(individual, families[1]);
            if (!spouse) {
                throw new Error(`Mising spouse for family ${families[1].id}`);
            }
            addSpouseBox(x + x_offset, y, spouse, shapes);

            let spouse_mid_x = x + x_offset + BOX_WIDTH / 2;
            let descendant_mid_x = x + x_offset - BOX_MARGIN - BOX_WIDTH / 2;
            let drop_x = x + x_offset;
            let link_bottom = row_bottom + BOX_HEIGHT * 0.5;
            let drop_bottom = row_bottom + BOX_HEIGHT * 0.75;

            addLine(
                shapes,
                spouse_mid_x,
                row_bottom,
                spouse_mid_x,
                link_bottom,
                BLACK,
                THIN,
                lineKey + counter++,
            );
            addLine(
                shapes,
                spouse_mid_x,
                link_bottom,
                drop_x,
                link_bottom,
                BLACK,
                THIN,
                lineKey + counter++,
            );
            addLine(
                shapes,
                drop_x,
                link_bottom,
                descendant_mid_x,
                link_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );
            addLine(
                shapes,
                descendant_mid_x,
                link_bottom,
                descendant_mid_x,
                row_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );
            addLine(
                shapes,
                drop_x,
                link_bottom,
                drop_x,
                drop_bottom,
                RED,
                THICK,
                lineKey + counter++,
            );

            spouse_line_drop_offsets.push([drop_x, drop_bottom]);
            x_offset += BOX_WIDTH + BOX_MARGIN; // Not needed
        }

        let child_x_offset = 0;
        let child_number = 0;
        let family_number = 0;
        for (let family of families) {
            // if (family.children.length === 0) {
            //     family_number++;
            //     continue;
            // }
            let parents_link_x = spouse_line_drop_offsets[family_number][0];
            let parents_link_y = spouse_line_drop_offsets[family_number][1];
            let firstChildGeometry = geometries.get(family.children[0].id);
            if (!firstChildGeometry) {
                throw new Error(
                    `Can't find geometry for child ${family.children[0].id}`,
                );
            }
            let min_child_link_x =
                x +
                child_x_offset +
                child_number * BOX_MARGIN +
                firstChildGeometry.linkOffset;
            let max_child_link_x = 0;
            for (const child of family.children) {
                let child_x_pos =
                    x + child_x_offset + child_number * BOX_MARGIN;
                this.walkIndividual(
                    child_x_pos,
                    y + BOX_HEIGHT + ARROW_HEIGHT,
                    shapes,
                    child,
                    geometries,
                );

                // Line from the parent's joint line, down to the descendant child.
                let geometry = geometries.get(child.id);
                if (!geometry) {
                    throw new Error(
                        `Can't find geometry for child ${family.children[0].id}`,
                    );
                }
                addLine(
                    shapes,
                    child_x_pos + geometry.linkOffset,
                    y + BOX_HEIGHT + ARROW_HEIGHT,
                    child_x_pos + geometry.linkOffset,
                    parents_link_y,
                    RED,
                    THICK,
                    lineKey + counter++,
                );
                child_number++;
                child_x_offset += geometry.width;
                max_child_link_x = Math.max(
                    max_child_link_x,
                    child_x_pos + geometry.linkOffset,
                );
            }
            addLine(
                shapes,
                Math.min(min_child_link_x, parents_link_x),
                parents_link_y,
                Math.max(max_child_link_x, parents_link_x),
                parents_link_y,
                RED,
                THICK,
                lineKey + counter++,
            );

            family_number++; // TODO: don't recalc this.
        }
    }
}

class AncestorsLoader implements Loader {
    walkIndividual(
        x: number,
        y: number,
        shapes: Shapes,
        individual: Individual,
        geometries: Map<number, Geometry>,
    ) {
        const geometry = geometries.get(individual.id);
        if (!geometry) {
            throw new Error(`Can't find geometry for ${individual.id}`);
        }

        let child_x = x + geometry.rowOffset;
        addDescendantBox(child_x, y, individual, shapes);

        if (individual.parents().length === 0) {
            return;
        }
        let lineKey = 'indi-line-' + individual.id + '-';
        let counter = 1;

        let child_middle_x = child_x + BOX_WIDTH / 2;
        let child_bottom_y = y + BOX_HEIGHT;
        let fork_bottom_y = child_bottom_y + ARROW_HEIGHT / 2;
        let parent_top_y = y + BOX_HEIGHT + ARROW_HEIGHT;

        addLine(
            shapes,
            child_middle_x,
            child_bottom_y,
            child_middle_x,
            fork_bottom_y,
            BLACK,
            THIN,
            lineKey + counter++,
        );

        let parent_x = x;
        let parent_min_link_x = x + geometry.width;
        let parent_max_link_x = parent_x;
        for (let parent of individual.parents()) {
            this.walkIndividual(
                parent_x,
                parent_top_y,
                shapes,
                parent,
                geometries,
            );
            const parentGeometry = geometries.get(parent.id);
            if (!parentGeometry) {
                throw new Error(`Can't find geometry for ${individual.id}`);
            }
            let parent_width = parentGeometry.width;
            let parent_middle_x = parent_x + parent_width / 2;
            addLine(
                shapes,
                parent_middle_x,
                fork_bottom_y,
                parent_middle_x,
                parent_top_y,
                BLACK,
                THIN,
                lineKey + counter++,
            );
            parent_min_link_x = Math.min(parent_min_link_x, parent_middle_x);
            parent_max_link_x = Math.max(parent_max_link_x, parent_middle_x);
            parent_x += parent_width + BOX_MARGIN;
        }
        if (parent_min_link_x < parent_max_link_x) {
            addLine(
                shapes,
                parent_min_link_x,
                fork_bottom_y,
                parent_max_link_x,
                fork_bottom_y,
                BLACK,
                THIN,
                lineKey + counter++,
            );
        }
    }

    calculateGeometry(
        individual: Individual,
        geometries: Map<number, Geometry>,
    ) {
        console.log(`calculateGeometry ${nameAndLifetimeOf(individual)}`);
        let baseWidth = BOX_WIDTH;
        let parents_width = 0;
        let parents_height = 0;
        const parents = individual.parents();
        for (const parent of parents) {
            let { width, height } = this.calculateGeometry(parent, geometries);
            parents_width += width;
            parents_height = Math.max(parents_height, height);
        }
        // Account for margin between parent rects.
        parents_width += BOX_MARGIN * Math.max(0, parents.length - 1);
        const width = Math.max(baseWidth, parents_width);
        const height =
            BOX_HEIGHT +
            (parents_height > 0 ? ARROW_HEIGHT + parents_height : 0);
        // Offset from the start of the box to the start of the
        // individual's box.
        const rowOffset = (width - baseWidth) / 2;
        const linkOffset = width / 2;

        const geometry = {
            width: width,
            height: height,
            baseWidth: baseWidth,
            rowOffset: rowOffset,
            linkOffset: linkOffset,
        };
        geometries.set(individual.id, geometry);

        return {
            width: width,
            height: height,
        };
    }
}

// window.localStorage.setItem("hide-relational-legend", "false")
const HIDE_TREE_LEGEND_KEY = 'hide-relational-legend';

interface RelationalTreeProps {
    individual: Individual;
    showDetails: IndividualCallback;
}

interface RelationalTreeState {
    zoom: number;
    x: number;
    y: number;
    hideLegend: boolean;
}

type GenericEventListener = (event: any) => void;

interface Handler {
    event: string;
    handler: GenericEventListener;
}

class RelationalTree extends Component<
    RelationalTreeProps,
    RelationalTreeState
> {
    state: RelationalTreeState = {
        zoom: MAX_ZOOM,
        x: 0.5,
        y: 0.5,
        hideLegend:
            window.localStorage.getItem(HIDE_TREE_LEGEND_KEY) === 'true',
    };

    width: number;
    height: number;
    shapes?: Shapes;

    isPointerDown: boolean;
    eventHandlers: Handler[];
    title: string;

    constructor(
        props: RelationalTreeProps,
        titlePrefix: string,
        loader: Loader,
    ) {
        super(props);

        this.eventHandlers = [
            { event: 'keyup', handler: this.keyUpHandler.bind(this) },
            {
                event: 'pointermove',
                handler: this.pointerMoveHandler.bind(this),
            },
            {
                event: 'pointerdown',
                handler: this.pointerDownHandler.bind(this),
            },
            { event: 'pointerup', handler: this.pointerUpHandler.bind(this) },
            { event: 'wheel', handler: this.wheelHandler.bind(this) },
        ];

        this.isPointerDown = false;
        this.title = titlePrefix + ' ' + nameAndLifetimeOf(props.individual);
        const { width, height, shapes } = this.load(
            this.props.individual,
            loader,
        );

        this.width = width;
        this.height = height;
        this.shapes = shapes;
    }

    hideLegend() {
        window.localStorage.setItem(HIDE_TREE_LEGEND_KEY, 'true');
        this.setState({
            hideLegend: true,
        });
    }

    componentDidMount() {
        for (const h of this.eventHandlers) {
            window.addEventListener(h.event, h.handler);
        }
    }

    componentWillUnmount() {
        for (const h of this.eventHandlers) {
            window.removeEventListener(h.event, h.handler);
        }
    }

    wheelHandler(e: MouseWheelEvent) {
        this.setState((state, props) => {
            return {
                zoom: Math.min(
                    MAX_ZOOM,
                    Math.max(MIN_ZOOM, state.zoom + e.deltaY / 50.0),
                ),
            };
        });
        e.preventDefault();
        e.stopPropagation();
    }

    pointerMoveHandler(e: PointerEvent) {
        if (!e.isPrimary || !this.isPointerDown) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        this.setState((state, props) => {
            const zoom = state.zoom / MAX_ZOOM;
            const dx = e.movementX / window.innerWidth;
            const dy = e.movementY / window.innerHeight;
            const x = state.x - dx * zoom;
            const y = state.y - dy * zoom;
            return {
                x: Math.min(1.0, Math.max(0, x)),
                y: Math.min(1.0, Math.max(0, y)),
            };
        });
    }

    pointerDownHandler(e: PointerEvent) {
        if (!e.isPrimary) {
            return;
        }
        this.isPointerDown = true;
    }

    pointerUpHandler(e: PointerEvent) {
        if (!e.isPrimary) {
            return;
        }
        this.isPointerDown = false;
    }

    keyUpHandler(e: KeyboardEvent) {
        const key = e.key;
        if (key === '+' || key === '=') {
            this.setState((state, props) => {
                return { zoom: Math.max(state.zoom - 1, MIN_ZOOM) };
            });
        } else if (key === '-' || key === '_') {
            this.setState((state, props) => {
                return { zoom: Math.min(state.zoom + 1, MAX_ZOOM) };
            });
        } else if (key === 'ArrowLeft') {
            this.setState((state, props) => {
                return { x: Math.max(state.x - 0.05, 0.0) };
            });
        } else if (key === 'ArrowRight') {
            this.setState((state, props) => {
                return { x: Math.min(state.x + 0.05, 1.0) };
            });
        } else if (key === 'ArrowUp') {
            this.setState((state, props) => {
                return { y: Math.max(state.y - 0.05, 0.0) };
            });
        } else if (key === 'ArrowDown') {
            this.setState((state, props) => {
                return { y: Math.min(state.y + 0.05, 1.0) };
                // That was "y: Math.min(state.y += 0.05, 1.0)" - so I wonder if we need to add 0.1 instead?
            });
        }
    }

    viewBox() {
        const w = this.width;
        const h = this.height;
        const zoom = this.state.zoom / MAX_ZOOM;
        const x = w * this.state.x - (w * zoom) / 2;
        const y = h * this.state.y - (h * zoom) / 2;
        return x + ',' + y + ',' + w * zoom + ',' + h * zoom;
    }

    render() {
        if (!this.shapes) {
            return <div>Downloading data...</div>;
        }

        const rects = this.shapes.rects.map((box) => {
            return (
                <rect
                    key={box.key}
                    x={box.x}
                    y={box.y}
                    width={box.width}
                    height={box.height}
                    stroke={box.stroke}
                    fill={box.fill}
                    strokeWidth={box.strokeWidth}
                    onClick={() => this.props.showDetails(box.id)}
                    onMouseEnter={(e) =>
                        e.currentTarget.classList.add('hover')
                    }
                    onMouseLeave={(e) =>
                        e.currentTarget.classList.remove('hover')
                    }
                ></rect>
            );
        });

        const texts = this.shapes.texts.map((t) => {
            return (
                <text
                    key={t.key}
                    x={t.x}
                    y={t.y}
                    width={t.width}
                    height={t.height}
                    onClick={() => this.props.showDetails(t.id)}
                >
                    {t.text}
                </text>
            );
        });

        const lines = this.shapes.lines.map((line) => {
            return (
                <line
                    key={line.key}
                    x1={line.x1}
                    y1={line.y1}
                    x2={line.x2}
                    y2={line.y2}
                    stroke={line.stroke}
                    strokeWidth={line.strokeWidth}
                ></line>
            );
        });

        const legend = this.state.hideLegend ? null : (
            <div className="relational-tree-legend">
                <div>To zoom in and out use mouse wheel, or press + or -</div>
                <div>To move around click and drag or use arrow keys.</div>
                <button onClick={() => this.hideLegend()}>
                    OK, hide this message!
                </button>
            </div>
        );

        return (
            <div id="svgcontainer">
                <div className="relational-tree-title">{this.title}</div>
                {legend}
                <svg
                    viewBox={this.viewBox()}
                    width={window.innerWidth - 200}
                    height={window.innerHeight - 200}
                >
                    {rects}
                    {texts}
                    {lines}
                </svg>
            </div>
        );
    }

    private load(individual: Individual, loader: Loader): AncestryTree {
        let geometries = new Map<number, Geometry>();
        const { width, height } = loader.calculateGeometry(
            individual,
            geometries,
        );
        let shapes = {
            rects: [],
            lines: [],
            texts: [],
            links: [],
        };
        loader.walkIndividual(0, 0, shapes, individual, geometries);

        return {
            width: width,
            height: height,
            shapes: shapes,
        };
    }
}

export class Descendants extends RelationalTree {
    constructor(props: RelationalTreeProps) {
        super(props, 'Descendants of', new DescendantsLoader());
    }
}

export class Ancestors extends RelationalTree {
    constructor(props: RelationalTreeProps) {
        super(props, 'Ancestors of', new AncestorsLoader());
    }
}
