import React, { Component } from 'react';
import { nameAndLifetimeOf } from './Utils';
import { Database, Individual, IndividualCallback } from './Data';

interface SearchProps {
    database: Database;
    showDetails: IndividualCallback;
}

interface SearchState {
    query: string;
    results?: Individual[];
}

export class Search extends Component<SearchProps, SearchState> {
    state: SearchState;
    timeout: any;

    constructor(props: SearchProps) {
        super(props);
        this.state = {
            query: '',
            results: undefined,
        };
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.timeout = null;
    }

    handleQueryChange(event: React.FormEvent<HTMLInputElement>) {
        console.log('handleQueryChange');
        this.setState({
            query: event.currentTarget.value,
            results: undefined,
        });

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const query = event.currentTarget.value;
        this.timeout = setTimeout(
            () => this.setState({ results: this.search(query) }),
            250,
        );
    }

    search(query: string) {
        return this.props.database.searchIndividuals(query);
    }

    searchResults() {
        if (this.state.results === undefined) {
            return '';
        }
        if (this.state.results.length === 0) {
            return <div>No results found.</div>;
        }

        const results = this.state.results.map((i) => (
            <li key={'individual_search' + i.id}>
                <button
                    className="searchResult"
                    onClick={() => this.props.showDetails(i.id)}
                >
                    {nameAndLifetimeOf(i)}
                </button>
            </li>
        ));
        return (
            <div>
                <ul>{results}</ul>
            </div>
        );
    }

    render() {
        return (
            <div>
                <label htmlFor="search-box">Search Individuals:</label>
                <input
                    name="query"
                    type="text"
                    size={30}
                    placeholder="Enter name to search for"
                    value={this.state.query}
                    onChange={this.handleQueryChange}
                />
                {this.searchResults()}
            </div>
        );
    }
}
